const COLUMN_IDS = {
  CHECKBOX: 'checkBox',
  PACKAGE_CODE: 'packageCode',
  PACKAGE_TYPE: 'packageType',
  ACCOUNTING_CODE: 'accountingCode',
  YEAR: 'year',
  ASSIGNED_PG: 'assignedPG'
};

const COLUMN_TYPE_ENUM = {
  SORTABLE_CD: 'sortableFilterDefinitionColumn'
};
export const columnTypes = {
  [COLUMN_TYPE_ENUM.SORTABLE_CD]: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  }
};

export const getColumnDefs = (lsi, readOnly) => {
  return [
    {
      colId: COLUMN_IDS.CHECKBOX,
      valueGetter: 'data && data.checkBox',
      checkboxSelection: !readOnly,
      showDisabledCheckboxes: true,
      width: 40,
      menuTabs: [],
      hide: readOnly
    },
    {
      colId: COLUMN_IDS.PACKAGE_CODE,
      valueGetter: 'data && data.packageCode',
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.PG_ACCOUNTING_HEADER.PG_PACKAGE'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.PG_ACCOUNTING_HEADER.PG_PACKAGE'),
      flex: 15,
      type: COLUMN_TYPE_ENUM.SORTABLE_CD
    },
    {
      colId: COLUMN_IDS.PACKAGE_TYPE,
      valueGetter: (value) => lsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.SALES_ACCOUNT_TYPE.${value.data?.packageType}`),
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.PG_ACCOUNTING_HEADER.SALES_ACCOUNT_TYPE'),
      flex: 15,
      type: COLUMN_TYPE_ENUM.SORTABLE_CD
    },
    {
      colId: COLUMN_IDS.ACCOUNTING_CODE,
      valueGetter: 'data && data.accountingCode',
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.PG_ACCOUNTING_HEADER.MAKU_ACTION'),
      flex: 22,
      type: COLUMN_TYPE_ENUM.SORTABLE_CD
    },
    {
      colId: COLUMN_IDS.YEAR,
      valueGetter: 'data && data.year',
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.PG_ACCOUNTING_HEADER.YEAR'),
      flex: 10,
      type: COLUMN_TYPE_ENUM.SORTABLE_CD
    },
    {
      colId: COLUMN_IDS.ASSIGNED_PG,
      valueGetter: 'data && data.assignedPG',
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.PG_ACCOUNTING_HEADER.ASSIGNED_PG'),
      flex: 20,
      type: COLUMN_TYPE_ENUM.SORTABLE_CD
    }
  ];
};