import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';
import {
  BalanceDTO,
  ProductionCorridorDTO,
  ProductionCorridorModelGroupChangeDTO,
  ProductionCorridorNewModelGroupDTO,
  ProductionCorridorNewRestrictionDTO,
  ProductionCorridorRestrictionChangeDTO
} from '@ovex/pvt-web-api';

import { booleanAGGridFormatter } from '../../../../../common/utils/formatters';
import { EXCEL_STYLES_IDS, GROUP_DISPLAY_TYPE, OvexAGTable } from '../../../../../common/components/ag-grid';
import { ConfirmModal } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';
import { ProductionCorridorRow, ProductionCorridorTypeEnum } from '../../objects';
import {
  LimitationProductionCorridorCreationForm,
  LimitationProductionCorridorForm,
  ModelGroupProductionCorridorCreationForm,
  ModelGroupProductionCorridorForm,
  ProductionCorridorButtonBar
} from '../index';

import { getContextMenuEditMode, getContextMenuReadOnlyMode } from './ProductionCorridorTableContextMenuItems';

import './ProductionCorridorTable.scss';
import BalanceRecalculationForm from '../BalanceRecalculationForm/BalanceRecalculationForm';

class ProductionCorridorTable extends React.PureComponent {

  static contextType = LsiContext;

  static propTypes = {
    onReloadProductionCorridor: PropTypes.func.isRequired,
    onUpdateProductionCorridor: PropTypes.func.isRequired,
    onUpdateBalance: PropTypes.func.isRequired,
    productionCorridorData: PropTypes.arrayOf(PropTypes.instanceOf(ProductionCorridorDTO)),
    balanceData: PropTypes.instanceOf(BalanceDTO)
  };

  static defaultProps = {
    productionCorridorData: null,
    balanceData: null
  };

  static columnTypes = {
    productionCorridorDefinitionColumn: {
      sortable: true,
      filter: true,
      filterParams: { newRowsAction: 'keep' },
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
    }
  };

  static rowClassRules = {
    'ProductionCorridorTable-row--model-group': (params) => {
      return !params.node.group && ProductionCorridorTypeEnum.isModelGroupRow(params.data.type);
    },
    'ProductionCorridorTable-row--limitation': (params) => {
      return !params.node.group && ProductionCorridorTypeEnum.isLimitationRow(params.data.type);
    },
    'ProductionCorridorTable-row--addedLimitation': (params) => {
      return !params.node.group && params.data.added && !params.data.isNew();
    },
    'ProductionCorridorTable-row--archivedFlag': (params) => {
      return !params.node.group && (params.data.archived || params.data.archivedModelGroup);
    },
    'ProductionCorridorTable-row--newLimitation': (params) => {
      return !params.node.group && params.data.isNew();
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.productionCorridorData !== state.prevPropsProductionCorridorData) {
      return {
        prevPropsProductionCorridorData: props.productionCorridorData,
        productionCorridorRows: ProductionCorridorTable.transformToProductionCorridorRows(props.productionCorridorData)
      };
    }
    return null;
  }

  /**
   * Obsah tabulky - transformace z {@link Array.<model/RestrictionDTO>}
   *
   * @param {Array.<module:model/ProductionCorridorDTO>} dtos
   */
  static transformToProductionCorridorRows = (dtos) => {
    if (!Array.isArray(dtos)) {
      return [];
    }
    return dtos.map(dto => ProductionCorridorRow.constructFromObject(dto));
  };

  constructor(props, context) {
    super(props, context);

    this.confirmModalRef = React.createRef();

    this.state = {
      prevPropsProductionCorridorData: null,

      editable: false,
      productionCorridorRows: null,

      modelGroupProductionCorridorCreationModalFormProps: {
        shown: false
      },
      modelGroupProductionCorridorModalFormProps: {
        shown: false,
        readOnly: true,
        rowData: null
      },
      limitationProductionCorridorCreationModalFormProps: {
        shown: false,
        modelGroup: null,
        limitWeekFrom: null,
        limitWeekTo: null,
        equipmentTypesAvailable: null
      },
      limitationProductionCorridorModalFormProps: {
        shown: false,
        readOnly: true,
        limitWeekFrom: null,
        limitWeekTo: null,
        rowData: null,
        equipmentType: null,
        equipmentTypesAvailable: null
      },
      balanceModalFormProps: {
        shown: false
      }
    };
  }

  handleRefTable = (refTable) => {
    this.refTable = refTable;
  };

  handlePostSort = (params) => {
    const rowNodes = params.nodes;
    const move = (toIndex, fromIndex) => {
      rowNodes.splice(toIndex, 0, rowNodes.splice(fromIndex, 1)[0]);
    };

    let nextInsertPos = 0;
    rowNodes.forEach((rowNode, idx) => {
      if (!rowNode.group) {
        if (ProductionCorridorTypeEnum.isModelGroupRow(rowNode.data.type)) {
          move(nextInsertPos, idx);
          nextInsertPos++;
        }
      }
    });
  };

  handleGetContextMenuItems = (params) => {
    const { editable } = this.state;

    if (editable) {
      return getContextMenuEditMode(params);
    }
    return getContextMenuReadOnlyMode(params);
  };

  // ----- Column definitions ------------------------------------------------------------------------------------------

  _columns = () => {
    const lsi = this.context;

    return [
      { colId: 'modelGroup',	field: 'modelGroup',	headerName: lsi.getLSIItem('PVT.TABLE_HEADER.GROUP'),			flex: 2, minWidth: 100,	type: ['productionCorridorDefinitionColumn'], rowGroup: true, sortable: false, cellClassRules: {	'ProductionCorridorTable-column--modified': (params) => !params.node.group && params.data.modified }, cellClass: ['ProductionCorridorTable-column--modelGroup', EXCEL_STYLES_IDS.STRING] },
      { colId: 'modelMask',		field: 'modelMask',		headerName: lsi.getLSIItem('PVT.TABLE_HEADER.MASK'),			flex: 2, minWidth: 100,	type: ['productionCorridorDefinitionColumn'], cellClass: EXCEL_STYLES_IDS.STRING },
      { colId: 'colour',			field: 'colour',			headerName: lsi.getLSIItem('PVT.TABLE_HEADER.COLOUR'),		flex: 2, minWidth: 100,	type: ['productionCorridorDefinitionColumn'], cellClass: EXCEL_STYLES_IDS.STRING },
      { colId: 'interior',		field: 'interior',		headerName: lsi.getLSIItem('PVT.TABLE_HEADER.INTERIOR'),	flex: 2, minWidth: 100,	type: ['productionCorridorDefinitionColumn'], cellClass: EXCEL_STYLES_IDS.STRING },
      { colId: 'equipment',		field: 'equipment',		headerName: lsi.getLSIItem('PVT.TABLE_HEADER.EQUIPMENT'),	flex: 2, minWidth: 100,	type: ['productionCorridorDefinitionColumn'], cellClass: EXCEL_STYLES_IDS.STRING, tooltipField: 'equipmentType' },
      { colId: 'annualTarget',	field: 'annualTarget',	headerName: lsi.getLSIItem('PVT.TABLE_HEADER.ANNUAL_TARGETS'),	tooltipValueGetter: this.handleGetAnnualTargetTooltip, flex: 3, minWidth: 110, cellRenderer: booleanAGGridFormatter },
      { colId: 'stockCar',			field: 'stockCar',			headerName: lsi.getLSIItem('PVT.TABLE_HEADER.STOCK_CAR'),				tooltipValueGetter: this.handleGetStockCarTooltip, flex: 3, minWidth: 130, cellRenderer: booleanAGGridFormatter },
      { colId: 'weekFrom',	field: 'weekFrom',	headerName: lsi.getLSIItem('PVT.TABLE_HEADER.WEEK_FROM'),	flex: 3, minWidth: 155,	type: ['productionCorridorDefinitionColumn'], headerTooltip: lsi.getLSIItem('PVT.TOOLTIP.WEEK_FROM')  },
      { colId: 'weekTo',		field: 'weekTo',		headerName: lsi.getLSIItem('PVT.TABLE_HEADER.WEEK_TO'),		flex: 3, minWidth: 155,	type: ['productionCorridorDefinitionColumn'], headerTooltip: lsi.getLSIItem('PVT.TOOLTIP.WEEK_TO')  }
    ];
  };

  handleGetAnnualTargetTooltip = (params) => {
    const lsi = this.context;

    if (params.value != null) {
      if (params.value) {
        return lsi.getLSIItem('PVT.TOOLTIP.ANNUAL_TARGETS_VALUE_YES');
      }
      return lsi.getLSIItem('PVT.TOOLTIP.ANNUAL_TARGETS_VALUE_NO');
    }
    return null;
  };

  handleGetStockCarTooltip = (params) => {
    const lsi = this.context;

    if (params.value != null) {
      if (params.value) {
        return lsi.getLSIItem('PVT.TOOLTIP.STOCK_CAR_VALUE_YES');
      }
      return lsi.getLSIItem('PVT.TOOLTIP.STOCK_CAR_VALUE_NO');
    }
    return null;
  };

  handleEnableEdit = () => {
    this.setState({ editable: true });
  };

  handleCancelEdit = (callback) => {
    this.setState({ editable: false }, callback);
  };

  handleCancelEditAndDiscardChanges = () => {
    if (this._hasChanges()) {
      this.handleCancelEdit(this.handleDiscardChanges);
    } else {
      this.handleCancelEdit();
    }
  };
  handleShowBalanceForm = () => {
    this.setState({ balanceModalFormProps: { shown: true } });
  };

  handleCloseBalanceForm = () => {
    this.setState({ balanceModalFormProps: { shown: false } });
  };

  handleUpdateBalance = async (balanceData) => {
    const { onUpdateBalance } = this.props;
    try {
      const response = await onUpdateBalance(balanceData);
      response && this.handleCancelEdit();
    } catch (e) {
    }

    this.handleCloseBalanceForm();
  };

  handleDiscardChanges = () => {
    const { productionCorridorData } = this.props;

    this.setState({ productionCorridorRows: ProductionCorridorTable.transformToProductionCorridorRows(productionCorridorData) });
  };

  _hasChanges = () => {
    const data = [];
    this.refTable.api.forEachLeafNode((node) => data.push(node.data));

    return data.some(productionCorridorRow => productionCorridorRow.isNew() || productionCorridorRow.modified || productionCorridorRow.archived);
  };

  // ----- Model group definitions - creation/update -------------------------------------------------------------------

  handleShowModelGroupProductionCorridorCreationForm = () => {
    this.setState({ modelGroupProductionCorridorCreationModalFormProps: { shown: true } });
  };

  handleCloseModelGroupProductionCorridorCreationForm = () => {
    this.setState({ modelGroupProductionCorridorCreationModalFormProps: { shown: false } });
  };

  handleSaveModelGroupProductionCorridor = (modelGroupProductionCorridorFormData) => {
    const row = ProductionCorridorRow.constructFromObject(modelGroupProductionCorridorFormData);

    this.refTable.api.applyTransaction({ add: [row] });

    this.handleCloseModelGroupProductionCorridorCreationForm();
  };

  handleShowModelGroupProductionCorridorUpdateForm = (rowData) => {
    this.setState({
      modelGroupProductionCorridorModalFormProps: {
        shown: true,
        readOnly: false,
        rowData: rowData
      }
    });
  };

  handleCloseModelGroupProductionCorridorForm = () => {
    this.setState({
      modelGroupProductionCorridorModalFormProps: {
        shown: false,
        readOnly: true,
        rowData: null
      }
    });
  };

  handleUpdateModelGroupProductionCorridor = (formData) => {
    const { modelGroupProductionCorridorModalFormProps } = this.state;

    modelGroupProductionCorridorModalFormProps.rowData.setModifiedData(formData);
    this.refTable.api.applyTransaction({ update: [modelGroupProductionCorridorModalFormProps.rowData] });

    this.handleCloseModelGroupProductionCorridorForm();
  };

  // ----- Limitation definitions - creation/update -------------------------------------------------------------------

  handleShowLimitationProductionCorridorCreationModalForm = (formData) => {
    const { productionCorridorData } = this.props;
    this.setState({
      limitationProductionCorridorCreationModalFormProps: {
        shown: true,
        modelGroup: formData.modelGroup,
        limitWeekFrom: formData.weekFrom,
        limitWeekTo: formData.weekTo,
        equipmentTypesAvailable: productionCorridorData[0].equipmentTypesAvailable
      }
    });
  };

  handleCloseLimitationProductionCorridorCreationModalForm = () => {
    this.setState({
      limitationProductionCorridorCreationModalFormProps: {
        shown: false,
        modelGroup: null,
        limitWeekFrom: null,
        limitWeekTo: null,
        equipmentTypesAvailable: null
      }
    });
  };

  handleSaveLimitationDefinition = (productionCorridorDefinition) => {
    const row = ProductionCorridorRow.constructFromObject(productionCorridorDefinition);

    this.refTable.api.applyTransaction({ add: [row] });

    this.handleCloseLimitationProductionCorridorCreationModalForm();
  };

  handleShowLimitationProductionCorridorUpdateForm = (formData) => {
    const { productionCorridorData } = this.props;
    this.setState({
      limitationProductionCorridorModalFormProps: {
        shown: true,
        readOnly: false,
        limitWeekFrom: formData.limitWeekFrom,
        limitWeekTo: formData.limitWeekTo,
        rowData: formData.rowData,
        equipmentType: formData.equipmentType,
        equipmentTypesAvailable: productionCorridorData[0].equipmentTypesAvailable
      }
    });
  };

  handleCloseLimitationProductionCorridorForm = () => {
    this.setState({
      limitationProductionCorridorModalFormProps: {
        shown: false,
        readOnly: true,
        limitWeekFrom: null,
        limitWeekTo: null,
        rowData: null,
        equipmentType: null,
        equipmentTypesAvailable: null
      }
    });
  };

  handleUpdateLimitationProductionCorridor = (formData) => {
    const { limitationProductionCorridorModalFormProps } = this.state;

    limitationProductionCorridorModalFormProps.rowData.setModifiedData(formData);
    this.refTable.api.applyTransaction({ update: [limitationProductionCorridorModalFormProps.rowData] });

    this.handleCloseLimitationProductionCorridorForm();
  };

  // ----- Save modified data ------------------------------------------------------------------------------------------

  _hasDataToArchive = () => {
    const data = [];
    this.refTable.api.forEachLeafNode((node) => data.push(node.data));

    return data.some(productionCorridorRow => productionCorridorRow.archived);
  };

  handleCheckArchivedDataBeforeSave = () => {
    if (this._hasDataToArchive()) {
      this.confirmModalRef.current.open();
    } else {
      this.handleSaveProductionCorridorChanges().then();
    }
  };

  handleSaveProductionCorridorChanges = async () => {
    const { onUpdateProductionCorridor } = this.props;

    const newModelGroups = [];
    const newLimitations = [];
    const modelGroupChanges = [];
    const limitationChanges = [];
    const archivedModelGroups = [];
    const archivedLimitationIds = [];

    this.refTable.api.forEachLeafNode((node) => {
      const data = node.data;
      if (data.isNew()) {
        if (ProductionCorridorTypeEnum.isModelGroupRow(data.type)) {
          newModelGroups.push(ProductionCorridorNewModelGroupDTO.constructFromObject(data));
        } else if (ProductionCorridorTypeEnum.isLimitationRow(data.type)) {
          newLimitations.push(ProductionCorridorNewRestrictionDTO.constructFromObject(data));
        }
      } else {
        if (data.modified) {
          if (ProductionCorridorTypeEnum.isModelGroupRow(data.type)) {
            modelGroupChanges.push(ProductionCorridorModelGroupChangeDTO.constructFromObject(data));
          } else if (ProductionCorridorTypeEnum.isLimitationRow(data.type)) {
            limitationChanges.push(ProductionCorridorRestrictionChangeDTO.constructFromObject(data));
          }
        }
        if (data.archived) {
          if (data.archivedModelGroup && ProductionCorridorTypeEnum.isModelGroupRow(data.type)) {
            archivedModelGroups.push(data.modelGroup);
          } else if (!data.archivedModelGroup && ProductionCorridorTypeEnum.isLimitationRow(data.type)) {
            archivedLimitationIds.push(data.id);
          }
        }
      }
    });

    if (newModelGroups.length > 0 || newLimitations.length > 0 || modelGroupChanges.length > 0 || limitationChanges.length > 0 || archivedModelGroups.length > 0 || archivedLimitationIds.length > 0) {
      try {
        const response = await onUpdateProductionCorridor({
          newModelGroups: newModelGroups.length > 0 ? newModelGroups : undefined,
          newRestrictions: newLimitations.length > 0 ? newLimitations : undefined,
          modelGroupChanges: modelGroupChanges.length > 0 ? modelGroupChanges : undefined,
          restrictionChanges: limitationChanges.length > 0 ? limitationChanges : undefined,
          archivedModelGroups: archivedModelGroups.length > 0 ? archivedModelGroups : undefined,
          archivedRestrictionIds: archivedLimitationIds.length > 0 ? archivedLimitationIds : undefined
        });
        response && this.handleCancelEdit();
      } catch (e) {
      }
    } else {
      this.handleCancelEdit();
    }
  };
  completeQuarters = (balanceData) => {
    const quartersArray = [];
    for (let i = 1; i <= 4; i++) {
      quartersArray.push({
        correction: i === balanceData.quarter ? balanceData.correction : false,
        quarter: i
      });
    }
    return quartersArray;
  }

  render() {
    const lsi = this.context;
    const { onReloadProductionCorridor, balanceData } = this.props;
    const {
      editable, productionCorridorRows,
      modelGroupProductionCorridorCreationModalFormProps,
      modelGroupProductionCorridorModalFormProps,
      limitationProductionCorridorCreationModalFormProps,
      limitationProductionCorridorModalFormProps,
      balanceModalFormProps
    } = this.state;

    const isBalanceActive = balanceData && this.completeQuarters(balanceData).some(val => val.correction === true);
    return (
      <Bricks.Div className='ProductionCorridorTable'>
        <Bricks.Div className={'ProductionCorridorTableHeader'}>
          <ProductionCorridorButtonBar
            editable={editable}
            onAddModelGroup={this.handleShowModelGroupProductionCorridorCreationForm}
            onCancelEdit={this.handleCancelEditAndDiscardChanges}
            onEnableEdit={this.handleEnableEdit}
            onRecalculateBalance={this.handleShowBalanceForm}
            onReload={onReloadProductionCorridor}
            onSave={this.handleCheckArchivedDataBeforeSave}
          />
          {isBalanceActive &&
            <Bricks.Text
              className='uu5-forms-label chck-label'
              colWidth='xs4'>
              <span
                className={'mdi mdi-information-outline'}
              />
              {lsi.getLSIItem('PVT.LABEL.BALANCE_ACTIVE')}
            </Bricks.Text>
          }
        </Bricks.Div>
        <OvexAGTable
          agContext={{
            editable,
            onShowModelGroupProductionCorridorUpdateForm: this.handleShowModelGroupProductionCorridorUpdateForm,
            onShowLimitationProductionCorridorCreationModalForm: this.handleShowLimitationProductionCorridorCreationModalForm,
            onShowLimitationProductionCorridorUpdateForm: this.handleShowLimitationProductionCorridorUpdateForm
          }}
          columnDefs={this._columns()}
          columnTypes={ProductionCorridorTable.columnTypes}
          enableRangeSelection
          getContextMenuItems={this.handleGetContextMenuItems}
          groupDefaultExpanded={1}
          groupDisplayType={GROUP_DISPLAY_TYPE.groupRows}
          groupHideOpenParents
          onGridReady={this.handleRefTable}
          postSortRows={this.handlePostSort}
          rowClassRules={ProductionCorridorTable.rowClassRules}
          rowData={productionCorridorRows}
        />
        {modelGroupProductionCorridorCreationModalFormProps.shown &&
          <ModelGroupProductionCorridorCreationForm
            onClose={this.handleCloseModelGroupProductionCorridorCreationForm}
            onSave={this.handleSaveModelGroupProductionCorridor}
            shown={modelGroupProductionCorridorCreationModalFormProps.shown}
          />
        }
        {modelGroupProductionCorridorModalFormProps.shown &&
          <ModelGroupProductionCorridorForm
            modelGroupProductionCorridor={modelGroupProductionCorridorModalFormProps.rowData}
            onClose={this.handleCloseModelGroupProductionCorridorForm}
            onSave={modelGroupProductionCorridorModalFormProps.readOnly ? undefined : this.handleUpdateModelGroupProductionCorridor}
            shown={modelGroupProductionCorridorModalFormProps.shown}
          />
        }
        {limitationProductionCorridorCreationModalFormProps.shown &&
          <LimitationProductionCorridorCreationForm
            equipmentTypesAvailable={limitationProductionCorridorCreationModalFormProps.equipmentTypesAvailable}
            limitWeekFrom={limitationProductionCorridorCreationModalFormProps.limitWeekFrom}
            limitWeekTo={limitationProductionCorridorCreationModalFormProps.limitWeekTo}
            modelGroup={limitationProductionCorridorCreationModalFormProps.modelGroup}
            onClose={this.handleCloseLimitationProductionCorridorCreationModalForm}
            onSave={this.handleSaveLimitationDefinition}
            shown={limitationProductionCorridorCreationModalFormProps.shown}
          />
        }
        {limitationProductionCorridorModalFormProps.shown &&
          <LimitationProductionCorridorForm
            equipmentType={limitationProductionCorridorModalFormProps.equipmentType}
            equipmentTypesAvailable={limitationProductionCorridorModalFormProps.equipmentTypesAvailable}
            limitationProductionCorridor={limitationProductionCorridorModalFormProps.rowData}
            limitWeekFrom={limitationProductionCorridorModalFormProps.limitWeekFrom}
            limitWeekTo={limitationProductionCorridorModalFormProps.limitWeekTo}
            onClose={this.handleCloseLimitationProductionCorridorForm}
            onSave={limitationProductionCorridorModalFormProps.readOnly ? undefined : this.handleUpdateLimitationProductionCorridor}
            shown={limitationProductionCorridorModalFormProps.shown}
          />
        }
        {balanceModalFormProps.shown &&
          <BalanceRecalculationForm
            onClose={this.handleCloseBalanceForm}
            onSave={this.handleUpdateBalance}
            quarters={this.completeQuarters(balanceData)}
            shown={balanceModalFormProps.shown}
          />
        }
        <ConfirmModal
          content={lsi.getLSIItem('PVT.CONFIRM_MESSAGE.ARCHIVE_PRODUCTION_CORRIDOR')}
          onConfirm={this.handleSaveProductionCorridorChanges}
          ref_={this.confirmModalRef}
        />
      </Bricks.Div>
    );
  }
}

export default ProductionCorridorTable;
