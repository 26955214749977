import { BooleanIconCellRenderer, EXCEL_STYLES_IDS } from '../../../../common/components/ag-grid';
import { booleanAGGridFormatter, dateFormatter } from '../../../../common/utils/formatters';

export const PG_COLUMN_IDS = {
  COMMISSION_CREATION_DATE_TO: 'commissionCreationDateTo',
  COMMISSION_PRODUCE_WEEK_FIRST_TO: 'commissionProduceWeekFirstTo',
  COMMISSION_SELLING_DATE_TO: 'commissionSellingDateTo',
  DAYS_FROM_INVOICE_TO_SALE: 'daysFromInvoiceToSale',
  NAME: 'name',
  MARKETING_ACTION: 'marketingAction',
  STATUS: 'status',
  TWO_WEEKS_AFTER_SALE: 'twoWeeksAfterSale',
  PG_ACCOUNTING_COUNT: 'pgAccountingCount',
  TYPE: 'type',
  CLAIM_CALCULATION_DATE: 'claimCalculationDate'
};

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    resizable: true,
    sortable: true,
    filter: true,
    filterParams: { buttons: ['reset'], newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  }
};

export const getColumnDefinitions = (lsi) => {

  return [
    {
      colId: PG_COLUMN_IDS.MARKETING_ACTION,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.MARKETING_ACTION'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.MARKETING_ACTION_CODE'),
      field: 'marketingActionCode',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 145,
      minWidth: 145
    },
    {
      colId: PG_COLUMN_IDS.NAME,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.NAME'),
      cellClass: [EXCEL_STYLES_IDS.STRING],
      field: 'name',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agTextColumnFilter',
      flex: 24,
      minWidth: 220
    },
    {
      colId: PG_COLUMN_IDS.TYPE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.EVALUATION_VARIANT'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.PRICE_GUARANTEE_EVALUATION_VARIANT'),
      field: 'type',
      tooltipValueGetter: typeTooltipValueGetter,
      valueFormatter: typeValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filterParams: {
        valueFormatter: typeValueFormatter
      },
      flex: 17,
      minWidth: 168
    },
    {
      colId: PG_COLUMN_IDS.STATUS,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.STATUS'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.STATUS'),
      field: 'status',
      valueFormatter: statusValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filterParams: {
        valueFormatter: statusValueFormatter
      },
      flex: 13,
      minWidth: 152
    },
    {
      colId: PG_COLUMN_IDS.COMMISSION_CREATION_DATE_TO,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.COMMISSION_CREATION_DATE_TO'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.COMMISSION_CREATION_DATE_TO'),
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.DATE],
      field: 'commissionCreationDateTo',
      valueFormatter: dateValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agDateColumnFilter',
      width: 125,
      minWidth: 125
    },
    {
      colId: PG_COLUMN_IDS.COMMISSION_PRODUCE_WEEK_FIRST_TO,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.COMMISSION_PRODUCE_WEEK_FIRST_TO'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.COMMISSION_PRODUCE_WEEK_FIRST_TO'),
      cellClass: ['ovex-ag-grid--td-right'],
      field: 'commissionProduceWeekFirstTo',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 160,
      minWidth: 160
    },
    {
      colId: PG_COLUMN_IDS.COMMISSION_SELLING_DATE_TO,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.COMMISSION_SELLING_DATE_TO'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.COMMISSION_SELLING_DATE_TO'),
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.DATE],
      field: 'commissionSellingDateTo',
      valueFormatter: dateValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agDateColumnFilter',
      width: 136,
      minWidth: 136
    },
    {
      colId: PG_COLUMN_IDS.DAYS_FROM_INVOICE_TO_SALE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.DAYS_FROM_INVOICE_TO_SALE'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.DAYS_FROM_INVOICE_TO_SALE'),
      cellClass: ['ovex-ag-grid--td-right'],
      field: 'daysFromInvoiceToSale',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 176,
      minWidth: 176
    },
    {
      colId: PG_COLUMN_IDS.TWO_WEEKS_AFTER_SALE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.TWO_WEEKS_AFTER_SALE'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.TWO_WEEKS_AFTER_SALE'),
      field: 'twoWeeksAfterSale',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      cellRenderer: BooleanIconCellRenderer,
      filterParams: {
        valueFormatter: booleanAGGridFormatter
      },
      width: 210,
      minWidth: 200
    },
    {
      colId: PG_COLUMN_IDS.PG_ACCOUNTING_COUNT,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PG_ACCOUNTING_BASIS'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.PG_ACCOUNTING_BASIS'),
      cellClass: ['ovex-ag-grid--td-right'],
      valueGetter: 'data && data.pgAccountingList.length',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 110,
      minWidth: 110
    },
    {
      colId: PG_COLUMN_IDS.CLAIM_CALCULATION_DATE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.CLAIM_CALCULATION_DATE'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_CALCULATION_DATE'),
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.DATE],
      field: 'claimCalculationDate',
      valueFormatter: dateValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agDateColumnFilter',
      width: 140,
      minWidth: 140
    }
  ];
};

const statusValueFormatter = (valueFormatterParams) => valueFormatterParams.context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.PRICE_GUARANTEE_STATUS.${valueFormatterParams.value}`);
const typeValueFormatter = (valueFormatterParams) => valueFormatterParams.context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.PRICE_GUARANTEE_TYPE.${valueFormatterParams.value}`);
const typeTooltipValueGetter = (valueFormatterParams) => valueFormatterParams.context.agLsi.getLSIItem(`PRICE_GUARANTEE.TOOLTIP.PRICE_GUARANTEE_TYPE.${valueFormatterParams.value}`);

const dateValueFormatter = (valueFormatterParams) => {
  const formatters = valueFormatterParams.context.agLsi.getFormatters();
  return dateFormatter(valueFormatterParams.value, formatters);
};
